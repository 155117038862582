const BASE_URL = "https://api.themoviedb.org/3/"
const POPULAR = "tv/popular"
const RECOMMENDED = "tv/{tv_id}/recommendations"
const API_KEY_PARAM = "?api_key=4ef12fb971a7f8029747f95e620e0c67"
const BACKDROP_BASE_URL = "http://www.themoviedb.org/t/p/original"
const SMALL_IMG_COVER_BASE_URL = "http://www.themoviedb.org/t/p/w300"

export {
  BASE_URL,
  POPULAR,
  RECOMMENDED,
  API_KEY_PARAM,
  BACKDROP_BASE_URL,
  SMALL_IMG_COVER_BASE_URL,
}
