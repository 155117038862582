import s from "./style.module.css"
import { Search as SearchIcon } from "react-bootstrap-icons"
import { useState } from "react"

export function SearchBar({ onSubmit }) {
  const [value,setValue] = useState("")
  function submit(e) {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      onSubmit(e.target.value)
      setValue("")
    }
  }

  function handleChange(event) {
    setValue(event.target.value) 
  }
  return (
    <>
      <SearchIcon className={s.icon} size={27} />
      <input
        onKeyUp={submit}
        onChange={handleChange}
        className={s.input}
        type='text'
        placeholder='tv show'
        value={value}
      />
    </>
  )
}
