import axios from "axios"
import { FAKE_POPULARS } from "./fake_populars"
import { FAKE_RECOMMENDATIONS } from "./fake_recommendations"
import { BASE_URL, POPULAR, API_KEY_PARAM } from "../config"

export class TVShowApi {
  static async fetchPopulars() {
    const response = await axios.get(`${BASE_URL}${POPULAR}${API_KEY_PARAM}`)
    return response.data.results
    //return FAKE_POPULARS
  }

  static async fetchRecommendations(tvShowId) {
    const response = await axios.get(
      `${BASE_URL}tv/${tvShowId}/recommendations${API_KEY_PARAM}`
    )
    return response.data.results
    //return FAKE_RECOMMENDATIONS
    //api.themoviedb.org/3/tv/undefined/recommendations?api_key=4ef12fb971a7f8029747f95e620e0c67:1
  }
  static async fetchByTitle(title) {
    const response = await axios.get(
      `${BASE_URL}search/tv${API_KEY_PARAM}&query=${title}`
    )
    return response.data.results
    //https://api.themoviedb.org/3/search/tv?query=Simpsons+meet%20&api_key=4ef12fb971a7f8029747f95e620e0c67
    //https://api.themoviedb.org/3/search/tv/?api_key=4ef12fb971a7f8029747f95e620e0c67&query=simpsons+meet
    //https://api.themoviedb.org/3/search/tv/?api_key=4ef12fb971a7f8029747f95e620e0c67&query=simpsons+meet
    //https://api.themoviedb.org/3/search/movie?api_key=4ef12fb971a7f8029747f95e620e0c67&query=Simpsons+meet
    //https://api.themoviedb.org/3/search/movie/?api_key=4ef12fb971a7f8029747f95e620e0c67&query=simpsons+mee
    //return FAKE_RECOMMENDATIONS
  }
}
