import s from "./style.module.css"
import { TVShowListItem } from "../TVShowListItem/TVShowListItem"

export function TVShowList({ tvShowList, onClickItem }) {
  return (
    <div>
      <div className={s.title}>You may also like:</div>
      <div className={s.list}>
        {tvShowList.map((tvShow) => {
          return (
            <span className={s.tv_show_item} key={tvShow.id}>
              <TVShowListItem
                key={tvShow.id}
                tvShow={tvShow}
                onClick={onClickItem}
              />
            </span>
          )
        })}
      </div>
    </div>
  )
}
