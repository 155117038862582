import s from "./style.module.css"

export const Logo = function ({ img, title, subtitle }) {
  return (
    <>
      <div className={s.logo_container}>
        <img src={img} alt='Logo' />
        <div className={s.title}>{title}</div>
      </div>
      <div className={s.subtitle}>{subtitle}</div>
    </>
  )
}
