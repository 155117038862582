import { useEffect, useState } from "react"
import { TVShowApi } from "./api/tv-show"
import s from "./style.module.css"
import { BACKDROP_BASE_URL } from "./config"
import { TVShowDetail } from "./components/TVShowDetail/TVShowDetail"
import { Logo } from "./components/Logo/Logo"
import logoImg from "./assets/images/logo.png"
//import { TVShowListItem } from "./components/TVShowListItem/TVShowListItem"
import { TVShowList } from "./components/TVShowList/TVShowList"
import { SearchBar } from "./components/SearchBar/SearchBar"

export function App() {
  const [currentTVShow, setCurrentTVShow] = useState()
  const [recommendationList, setRecommendationList] = useState([])

  async function fetchPopulars() {
    try {
      const popularTVShowList = await TVShowApi.fetchPopulars()
      if (popularTVShowList.length > 0) {
        setCurrentTVShow(popularTVShowList[0])
      }
    } catch (error) {
        alert("Something went wrong fetching POPULARS")
    }
  }

  async function fetchRecommendations(tvShowId) {
    try {
    const recommendationListResp = await TVShowApi.fetchRecommendations(
      tvShowId
    )
    if (recommendationListResp.length > 0) {
      setRecommendationList(recommendationListResp.slice(0, 10))
      }
    } catch (error) {
        alert("Something went wrong fetching RECOMMENDATIONS")

    }
  }

  async function fetchByTItle(title) {
    try {
      const searchResponse = await TVShowApi.fetchByTitle(title)
      if (searchResponse.length > 0) {
        setCurrentTVShow(searchResponse[0])
      }
    } catch (error) {
        alert("Something went wrong fetching TITLES")

    }
  }

  useEffect(() => {
    fetchPopulars()
  }, [])

  useEffect(() => {
    if (currentTVShow) {
      fetchRecommendations(currentTVShow.id)
    }
  }, [currentTVShow])

  function updateCurrentTVShow(tvShow) {
    setCurrentTVShow(tvShow)
  }

  return (
    <div
      className={s.container}
      style={{
        maxWidth: "100%",
        maxHeight: "100%",
        background: currentTVShow
          ? `linear-gradient(rgba(0,0,0,0.55),rgba(0,0,0,0.55)), url("${BACKDROP_BASE_URL}${currentTVShow.backdrop_path}") no-repeat center / cover`
          : "green",
      }}
    >
      <div className={s.header}>
        <div className='row'>
          <div className='col-4'>
            <Logo
              img={logoImg}
              title='Watowatch'
              subtitle='Find a show you may like'
            />
          </div>
          <div className='col-md-12 col-lg-4'>
            <SearchBar onSubmit={fetchByTItle}/>
          </div>
        </div>
      </div>
      <div>
        {currentTVShow && (
          <TVShowDetail tvShow={currentTVShow} />
        )}
      </div>
      <div className={s.recommended_tv_shows}>
        {currentTVShow && (
          <TVShowList
            onClickItem={updateCurrentTVShow}
            tvShowList={recommendationList}
          />
        )}
      </div>
    </div>
  )
}
