import { Star as StarEmpty, StarFill, StarHalf } from "react-bootstrap-icons"

export function FiveStarRating({ rating }) {
  // todo: Declare star icon array
  const starList = []
  // todo: Store number of filled stars
  const starFillCount = Math.floor(rating)
  // todo: isThisHalfStar
  const hasHalfStar = rating - parseInt(rating) >= 0.5
  // todo: Push the filled star icons
  const emptyStarCount = 5 - starFillCount - (hasHalfStar ? 1 : 0)
  // todo: Push on half star if necessary
  for (let i = 0; i < starFillCount; i++) {
    starList.push(<StarFill key={"star-fill"+i} />)
  }
  // todo: Push the empty star icons
  if (hasHalfStar) {
    starList.push(<StarHalf key={"star-fill"} />)
  }
  // todo: render the star icon array
  for (let i = 0; i < emptyStarCount; i++) {
    starList.push(<StarEmpty key={"star-empty" + i} />)
  }
  return (
    <div>{starList}</div>
  )
}
